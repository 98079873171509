.info-block {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    user-select: none;
}

.info-block>* {
    flex: 1 1 160px;
    user-select: none;
}

.info-block-image-right {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    text-align: right;
    user-select: none;
}

.info-block-image-right > * {
    flex: 1 1 160px;
    user-select: none;
}

.info-block-image-left {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    text-align: left;
    user-select: none;
}

.info-block-image-left > * {
    flex: 1 1 160px;
    user-select: none;
}

.header {
    color: rgb(200, 200, 200);
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem;
}

.article {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-width: 200px;
    color: rgb(200, 200, 200);
    font-size: 1.2rem;
    line-height: 1.5;
}

.article > p {
    margin: 4px
}

.detailsUrl {
    text-decoration: underline;
    color: aqua;
}

.image-right {
    padding: 1rem;
    text-align: left;
    @media screen and (max-width: 480px){
        text-align: center;
    }
}

.image-left {
    padding: 1rem;
    text-align: right;
    @media screen and (max-width: 480px){
        text-align: center;
    }
}

.book-fragment {
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.2rem;
    line-height: 1.5;
}

pre {
    text-align: left;
    white-space: pre-wrap;
}

.download-section {
    max-width: 1024px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    column-gap: 1rem;
}

.page-number-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.page-number-grid div {
    min-width: 33%;
    display: flex;
    justify-content: center;
    text-align: center;
}