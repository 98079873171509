@keyframes colorize {
    from {
        background-color: rgb(172, 172, 172);
    }
    to {
        background-color: rgb(218, 160, 0);
    }
}

.litres-download-button:hover {
    color: black;
    box-shadow: none;
    background-color: rgb(218, 160, 0);
    animation-name: colorize;
    animation-duration: 0.5s;
}

.litres-download-button {
    padding: 0.5rem 1rem;
    width: 200px;
    min-height: 40px;
    color: black;
    background-color: rgb(172, 172, 172);
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    align-self: center;
    column-gap: 1rem;
    justify-content: space-around;
    margin: 1rem 0;
}