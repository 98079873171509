nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    background: linear-gradient(to bottom, #555, #222);
    border-bottom: 1px solid black;
    box-shadow: 0 2px 15px black;
}

a {
    @media screen and (max-width: 480px){
        font-size: 0.5rem;
    }
    color: #a1a1a1;
    padding: 0 1rem;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 700;
}

@keyframes colorizeNav {
    from {color: #a1a1a1}
    to {color: rgb(218, 160, 0);}
}

a:hover {
    color: rgb(218, 160, 0);
    animation-name: colorizeNav;
    animation-duration: 0.5s;
}

.nav-links {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    list-style: none;
}

.nav-active {
    color: rgb(218, 160, 0);
}