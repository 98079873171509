.footer {
    position: relative;
    height: 149px;
    clear: both;
    font-size: 70%;
    background-color: #000;
    border-top: 1px solid #555;
    padding-top: 10px;
    color: white;
}

.footer-lines {
    list-style-type: none;
    padding-inline-start: 0;
}

.footer-line {
    text-decoration: none;
}