.info-block {
    max-width: 1024px;
    display: flex;
    flex-wrap: wrap;
}

.info-block>* {
    flex: 1 1 160px;
}

.header {
    color: rgb(200, 200, 200);
    font-size: 1.5rem;
    font-weight: 500;
    padding: 1rem;
}

.block-gallery {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 2.5rem;
    padding: 1rem;
    color: rgb(200, 200, 200);
}

.block-gallery >p {
    margin: 4px
}