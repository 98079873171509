.page {
    min-height: 800px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.page-header {
    width: 60%;
}

.page-footer {
    width: 60%;
}

.splitter {
    width: 60%;
    height: 1px;
    background-color: lightGrey;
    margin: 1rem 0;
}

.geanre-header {
    background-color: rgba(255, 0, 0, 0.5);
    text-align: right;
}

.geanre-title {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem;
}

.geanre-underline {
    height: 4px;
    background-color: orange;
}

.mail-sent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.feedback-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.input-label {
    color: white;
    display: flex;
    flex-direction: row;
    font-weight: 500;
    padding-left: 1rem;
    padding-bottom: 4px;
}

.name-input {
    background-color: rgb(230, 230, 230);
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: bold;
}

.mail-to-form {
    background-color: rgb(230, 230, 230);
    min-height: 100px;
    max-height: 300px;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 1.1rem;
    font-weight: 600;
}